import React from 'react';
import './embed.scss';

export default props => {
    
    return (
        <div className="embed">
            <iframe
                allow="autoplay"
                frameBorder="0"
                loading="lazy"
                scrolling="no"
                src={props.src}
                style={props.style}
                title={props.title}
            >
            </iframe>
        </div>
        );
    };